const EthIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_754_760)">
        <g clipPath="url(#clip1_754_760)">
          <path
            d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
            fill="#627EEA"
          />
          <path
            d="M23.7158 5.75V18.5006L34.4928 23.3162L23.7158 5.75Z"
            fill="white"
            fillOpacity="0.602"
          />
          <path
            d="M23.7159 5.75L12.9375 23.3162L23.7159 18.5006V5.75Z"
            fill="white"
          />
          <path
            d="M23.7158 31.5783V40.2421L34.4999 25.3223L23.7158 31.5783Z"
            fill="white"
            fillOpacity="0.602"
          />
          <path
            d="M23.7159 40.2421V31.5768L12.9375 25.3223L23.7159 40.2421Z"
            fill="white"
          />
          <path
            d="M23.7158 29.5741L34.4928 23.3167L23.7158 18.5039V29.5741Z"
            fill="white"
            fillOpacity="0.2"
          />
          <path
            d="M12.9375 23.3167L23.7159 29.5741V18.5039L12.9375 23.3167Z"
            fill="white"
            fillOpacity="0.602"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_754_760">
          <rect width="46" height="46" fill="white" />
        </clipPath>
        <clipPath id="clip1_754_760">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EthIcon;
