const TrxIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_849_391)">
        <path
          d="M44.2393 13.8581C42.083 11.8672 39.1002 8.82686 36.6708 6.67061L36.5271 6.56999C36.2879 6.37792 36.0182 6.22729 35.7293 6.12436V6.12436C29.8714 5.03186 2.60926 -0.0640771 2.07738 0.00061041C1.92835 0.021483 1.78589 0.0755175 1.66051 0.158735L1.52395 0.266548C1.35579 0.437323 1.22807 0.643636 1.1502 0.870298L1.11426 0.963735V1.47405V1.55311C4.18332 10.099 16.3014 38.0944 18.6877 44.6637C18.8314 45.1094 19.1046 45.9575 19.6149 46.0006H19.7299C20.003 46.0006 21.1674 44.4625 21.1674 44.4625C21.1674 44.4625 41.9824 19.22 44.0883 16.5319C44.3609 16.2007 44.6016 15.8446 44.8071 15.4681C44.8595 15.1736 44.8348 14.8704 44.7353 14.5883C44.6358 14.3062 44.4649 14.0546 44.2393 13.8581V13.8581ZM26.5077 16.7978L35.3914 9.43061L40.6024 14.2319L26.5077 16.7978ZM23.0577 16.3162L7.7627 3.78124L32.5093 8.3453L23.0577 16.3162ZM24.4377 19.6009L40.0921 17.0781L22.1952 38.6406L24.4377 19.6009ZM5.68551 5.03186L21.7783 18.6881L19.4496 38.655L5.68551 5.03186Z"
          fill="#FF060A"
        />
      </g>
      <defs>
        <clipPath id="clip0_849_391">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrxIcon;
