import logo from "../../images/logo-white.svg";
import play from "../../images/play.svg";
import Menu from "./Menu";
import React, { Suspense, useState } from "react";
import "./Main.css";
import Calculator from "../calculator/Calculator";
import burger from "../../images/burger.svg";
import BurgerMenu from "./BurgerMenu";
import close from "../../images/close.svg";
import bird from "../../images/bird.svg";

function MainSection({ refCode }) {
  const [showBurger, setShowBurger] = useState(false);
  const Bubble = React.lazy(() => import("./Bubble"));

  return (
    <div className="section main-section" id={"main"}>
      <BurgerMenu show={showBurger} setShow={setShowBurger} />
      <div
        style={{ zIndex: 2, position: "relative" }}
        className={"content-container"}
      >
        <div className="header">
          <div
            className={"logo"}
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <img src={logo} alt={"logo"} className={"logo"} />
          </div>
          <Menu />
          <div className={"language"}>
            EN <img src={bird} alt={"bird"} />{" "}
          </div>
          <div className={"burger"}>
            <img
              className={"burger-button"}
              src={burger}
              alt={"menu"}
              onClick={() => {
                setShowBurger(!showBurger);
              }}
            />
          </div>
        </div>
        <div className="main">
          <div className="main-text">
            <h1>A safe bridge between traditional money and cryptocurrency</h1>
            <p>Buy stablecoins, sell Bitcoin, Ethereum and many other tokens</p>

            <Calculator className={"mobile"} refCode={refCode} />

            <div className="buttons-block">
              <button
                className="light open-app"
                //  style={{ width: "191px" }}
                onClick={() => {
                  window.open(
                    `https://app.ravestag.com${
                      refCode ? "/?ref=" + refCode : ""
                    }`,
                    "_blank"
                  );
                }}
              >
                Open app
              </button>
              <button
                className="dark watch"
                onClick={() => {
                  window.open(
                    "https://youtube.com/playlist?list=PLgwExMrvKuwibXpYEAfa70d5wbPEjslhb",
                    "_blank"
                  );
                }}
              >
                <img src={play} alt={"play"} /> Watch tutorial
              </button>
            </div>
          </div>
          <div className="calc">
            <Calculator className={"desktop"} refCode={refCode} />
            {/*<img
              style={{ marginRight: "3vw" }}
              src={calculator}
              alt={"calculator"}
            />*/}
          </div>
        </div>
      </div>
      <div>
        <Suspense fallback={<div></div>}>
          <Bubble />
        </Suspense>
      </div>
    </div>
  );
}

export default MainSection;
