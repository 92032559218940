const EurIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
        fill="#0F8FF8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 27.3182L12.6644 24.4375H13.8963C13.8644 23.9446 13.8481 23.4508 13.8474 22.9569C13.8474 22.3129 13.8748 21.7149 13.9279 21.16H11.5L12.6644 18.2807H14.4957C16.2006 12.8024 20.0445 10.0625 26.0231 10.0625C27.9881 10.0625 29.8554 10.3169 31.625 10.8273V14.4555C29.9597 13.7113 28.1547 13.331 26.3307 13.34C22.747 13.34 20.4427 14.9874 19.4163 18.2821H30.0869L28.9225 21.1614H18.7206C18.6595 21.6357 18.6326 22.1137 18.6401 22.5917C18.6401 23.2803 18.6616 23.8956 18.7048 24.4389H27.5928L26.4428 27.3182H19.32C20.0862 29.3149 21.0206 30.7036 22.1217 31.487C23.2214 32.269 24.7926 32.66 26.8324 32.66C28.29 32.66 29.8871 32.3049 31.625 31.5948V35.006C29.9733 35.627 28.0097 35.9375 25.7312 35.9375C19.7412 35.9375 15.9519 33.0639 14.3664 27.3182H11.5Z"
        fill="white"
      />
    </svg>
  );
};

export default EurIcon;
