const DaiIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
        fill="#F4B731"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3357 11.5H22.7542C28.4826 11.5 32.8253 14.5418 34.4411 18.9664H37.375V21.6416H35.0592C35.1038 22.0642 35.1268 22.4954 35.1268 22.9324V22.9986C35.1268 23.4902 35.098 23.9761 35.0405 24.4504H37.375V27.1242H34.385C32.7276 31.4884 28.4194 34.5 22.7556 34.5H13.3357V27.1242H10.0625V24.4504H13.3357V21.6416H10.0625V18.9678H13.3357V11.5ZM15.9677 27.1242V32.1008H22.7528C26.9416 32.1008 30.0524 30.107 31.4999 27.1242H15.9677ZM32.3064 24.4504H15.9677V21.6416H32.3121C32.3711 22.0829 32.4027 22.5357 32.4027 22.9986V23.0633C32.4027 23.5362 32.3696 23.9976 32.3064 24.449V24.4504ZM22.7556 13.8934C26.9618 13.8934 30.0826 15.9404 31.5215 18.9649H15.9677V13.8949H22.7528L22.7556 13.8934Z"
        fill="white"
      />
    </svg>
  );
};

export default DaiIcon;
