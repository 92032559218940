// import Snowfall from 'react-snowfall'

import "./App.css";
import MainSection from "./sections/main/MainSection";

import React, { useEffect } from "react";
import Promo from "./sections/Promo";

const ServiceSection = React.lazy(() =>
  import("./sections/services/ServiceSection")
);
const StepsSection = React.lazy(() => import("./sections/steps/StepsSection"));
const OfficesSection = React.lazy(() =>
  import("./sections/offices/OfficesSection")
);
const ReferralSection = React.lazy(() =>
  import("./sections/referrals/ReferralSection")
);
const PartnersSection = React.lazy(() =>
  import("./sections/partners/PartnersSection")
);
const FooterSection = React.lazy(() =>
  import("./sections/footer/FooterSection")
);
const FaqsSection = React.lazy(() => import("./sections/faq/FaqsSection"));

function App() {
  const referralCode = window.location.href.split("ref=")[1];

  return (
    <div className="wrapper">
      <div className="container">
        {/*<Snowfall snowflakeCount={50} />*/}
        <MainSection refCode={referralCode} />
        <ServiceSection refCode={referralCode} />
        <StepsSection refCode={referralCode} />
        <OfficesSection />
        <ReferralSection refCode={referralCode} />
        <FaqsSection />

        <PartnersSection />
        <FooterSection refCode={referralCode} />
        {/*<Promo />*/}
      </div>
    </div>
  );
}

export default App;
