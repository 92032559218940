import "./BurgerMenu.css";
import arrow from "../../images/arrow.svg";
import React, { useEffect } from "react";
import YoutubeLight from "../../images/socials/YoutubeLight";
import IGLight from "../../images/socials/IGLight";
import TGLight from "../../images/socials/TGLight";
import TWTLight from "../../images/socials/TWTLight";
import logo from "../../images/logo-white.svg";
import close from "../../images/close.svg";

function BurgerMenu({ show, setShow }) {
  //lock scroll if menu is open
  useEffect(() => {}, [show]);
  const scrollToAnchor = (anchorName) => {
    const anchor = document.querySelector(anchorName);
    anchor.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div
      className="burger-menu"
      hidden={!show}
      style={!show ? { display: "none" } : { display: "flex" }}
      id={"burger-menu"}
    >
      <div className="burger-menu-header">
        <img
          src={logo}
          alt={"logo"}
          style={{ margin: "unset" }}
          className={"logo"}
        />
        <img
          className={"burger-button"}
          src={close}
          style={{ width: "23px", height: "23px" }}
          alt={"menu"}
          onClick={() => {
            setShow(false);
          }}
        />
      </div>
      <div className="burger-menu-content">
        <div
          className="burger-menu-item"
          onClick={() => {
            scrollToAnchor("#services");
            setShow(false);
          }}
        >
          <a onClick={() => scrollToAnchor("#services")}>Services</a>
          <img src={arrow} alt={"arrow"} />
        </div>
        <div
          className="burger-menu-item"
          onClick={() => {
            scrollToAnchor("#how_to");
            setShow(false);
          }}
        >
          <a>How to start?</a>
          <img src={arrow} alt={"arrow"} />
        </div>
        <div
          className="burger-menu-item"
          onClick={() => {
            setShow(false);
            scrollToAnchor("#offices");
          }}
        >
          <a>Offices</a>
          <img src={arrow} alt={"arrow"} />
        </div>
        <div
          className="burger-menu-item"
          onClick={() => {
            setShow(false);
            scrollToAnchor("#referrals");
          }}
        >
          <a>Referral program</a>
          <img src={arrow} alt={"arrow"} />
        </div>
        <div
          className="burger-menu-item"
          onClick={() => {
            setShow(false);
            scrollToAnchor("#faq");
          }}
        >
          <a>FAQ</a>
          <img src={arrow} alt={"arrow"} />
        </div>
        <div
          className="burger-menu-item"
          onClick={() => {
            setShow(false);
            scrollToAnchor("#partners");
          }}
        >
          <a>Our partners</a>
          <img src={arrow} alt={"arrow"} />
        </div>
      </div>
      <div className="burger-menu-footer">
        <div className="burger-menu-footer-item">
          <YoutubeLight />

          <IGLight />

          <TGLight />

          <TWTLight />
        </div>
      </div>
    </div>
  );
}

export default BurgerMenu;
