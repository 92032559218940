const SolIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="37"
      viewBox="0 0 46 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_849_387)">
        <path
          d="M7.47234 27.5177C7.74994 27.2401 8.13163 27.0781 8.53646 27.0781H45.2486C45.9194 27.0781 46.2548 27.8878 45.7806 28.362L38.5284 35.6142C38.2508 35.8918 37.8691 36.0537 37.4643 36.0537H0.752201C0.081344 36.0537 -0.254085 35.2441 0.220142 34.7699L7.47234 27.5177Z"
          fill="url(#paint0_linear_849_387)"
        />
        <path
          d="M7.47234 0.439527C7.7615 0.161931 8.1432 0 8.53646 0H45.2486C45.9194 0 46.2548 0.809655 45.7806 1.28388L38.5284 8.53608C38.2508 8.81368 37.8691 8.97561 37.4643 8.97561H0.752201C0.081344 8.97561 -0.254085 8.16595 0.220142 7.69173L7.47234 0.439527Z"
          fill="url(#paint1_linear_849_387)"
        />
        <path
          d="M38.5284 13.8907C38.2508 13.6131 37.8691 13.4512 37.4643 13.4512H0.752201C0.081344 13.4512 -0.254085 14.2608 0.220142 14.7351L7.47234 21.9873C7.74994 22.2649 8.13163 22.4268 8.53646 22.4268H45.2486C45.9194 22.4268 46.2548 21.6171 45.7806 21.1429L38.5284 13.8907Z"
          fill="url(#paint2_linear_849_387)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_849_387"
          x1="41.7415"
          y1="-4.33134"
          x2="16.3338"
          y2="44.3346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_849_387"
          x1="30.6319"
          y1="-10.1324"
          x2="5.22416"
          y2="38.5335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_849_387"
          x1="36.1513"
          y1="-7.25148"
          x2="10.7436"
          y2="41.4145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <clipPath id="clip0_849_387">
          <rect width="46" height="36.0528" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SolIcon;
