const XmrIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="47"
      viewBox="0 0 46 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_849_394)">
        <path
          d="M45.9999 23.0002C45.9999 35.7024 35.7035 46.0002 23.0004 46.0002C10.2973 46.0002 0.000976562 35.7024 0.000976562 23.0002C0.000976562 10.2979 10.2976 0 23.0004 0C35.7033 0 45.9999 10.297 45.9999 23.0002Z"
          fill="white"
        />
        <path
          d="M22.9999 0C10.3014 0 -0.0132171 10.3125 0.00294866 22.9994C0.00613283 25.5377 0.410889 27.9794 1.17056 30.2633H8.05178V10.9149L22.9999 25.863L37.9474 10.9149V30.2636H44.8301C45.5908 27.9798 45.9935 25.5382 45.998 22.9998C46.0197 10.2997 35.6999 0.00306169 22.9999 0.00306169V0Z"
          fill="#F26822"
        />
        <path
          d="M19.562 29.2992L13.0385 22.7754V34.9504H8.05104L3.34473 34.9513C7.38163 41.5743 14.6773 46.0047 22.9997 46.0047C31.3221 46.0047 38.6181 41.5733 42.6558 34.9503H32.9594V22.7754L26.4355 29.2992L22.999 32.7358L19.5623 29.2992H19.562Z"
          fill="#4D4D4D"
        />
      </g>
      <defs>
        <clipPath id="clip0_849_394">
          <rect width="46" height="46.0049" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default XmrIcon;
