import "./YoutubeLight.css";
function YoutubeLight() {
  return (
    <svg
      onClick={() => {
        window.open("https://www.youtube.com/@RavestagCrypto", "_blank");
      }}
      className="yt"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5486 29.5317C32.3815 29.272 33.8653 27.8871 34.2145 26.0693C34.5636 24.0785 34.9127 21.1355 35 17.5C35 13.8645 34.5636 10.9215 34.2145 8.93063C33.8653 7.11289 32.3815 5.72794 30.5486 5.46826C27.581 5.03547 22.8678 4.51611 17.4564 4.51611C12.0449 4.51611 7.24439 5.03547 4.36409 5.46826C2.53117 5.72794 1.04738 7.11289 0.698255 8.93063C0.349127 10.9215 0 13.8645 0 17.5C0 21.1355 0.349127 24.0785 0.698255 26.0693C1.04738 27.8871 2.53117 29.272 4.36409 29.5317C7.33167 29.9645 12.1322 30.4839 17.4564 30.4839C22.8678 30.4839 27.6683 29.9645 30.5486 29.5317ZM23.7097 17.5L13.5484 12.4193V22.5806L23.7097 17.5Z"
      />
    </svg>
  );
}
export default YoutubeLight;
