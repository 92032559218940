const LtcIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_849_384)">
        <path
          d="M22.9999 43.5115C34.3276 43.5115 43.5106 34.3286 43.5106 23.0009C43.5106 11.6732 34.3276 2.49023 22.9999 2.49023C11.6722 2.49023 2.48926 11.6732 2.48926 23.0009C2.48926 34.3286 11.6722 43.5115 22.9999 43.5115Z"
          fill="white"
        />
        <path
          d="M23 0C18.451 0 14.0042 1.34893 10.2219 3.8762C6.43957 6.40347 3.4916 9.99558 1.75078 14.1983C0.00996546 18.401 -0.445511 23.0255 0.441949 27.4871C1.32941 31.9486 3.51995 36.0468 6.73655 39.2635C9.95316 42.4801 14.0514 44.6706 18.5129 45.5581C22.9745 46.4455 27.599 45.99 31.8017 44.2492C36.0044 42.5084 39.5965 39.5604 42.1238 35.7781C44.6511 31.9958 46 27.549 46 23V23C46.0088 19.9884 45.4243 17.0045 44.2799 14.2188C43.1355 11.433 41.4537 8.89995 39.3303 6.7642C37.207 4.62845 34.6838 2.93184 31.9047 1.77123C29.1257 0.610629 26.1453 0.00876321 23.1337 0H23ZM23.3898 23.7797L20.9952 31.8547H33.8039C33.8887 31.8517 33.9733 31.8655 34.0529 31.8953C34.1324 31.925 34.2052 31.9701 34.2673 32.0281C34.3294 32.086 34.3794 32.1556 34.4146 32.2329C34.4497 32.3101 34.4693 32.3936 34.4722 32.4785V32.6901L33.3584 36.5327C33.3093 36.7144 33.1998 36.874 33.048 36.9853C32.8962 37.0967 32.7111 37.1531 32.523 37.1453H12.9201L16.2058 25.9516L12.5303 27.0654L13.3656 24.5036L17.0412 23.3898L21.6635 7.68523C21.7143 7.50453 21.8242 7.34605 21.9756 7.23504C22.127 7.12402 22.3112 7.06686 22.4988 7.07264H27.4552C27.5401 7.06966 27.6247 7.08344 27.7042 7.11318C27.7837 7.14293 27.8566 7.18805 27.9186 7.24598C27.9807 7.30391 28.0307 7.3735 28.0659 7.45079C28.101 7.52807 28.1206 7.61152 28.1235 7.69637V7.90799L24.2252 21.1622L27.9007 20.0484L27.1211 22.7215L23.3898 23.7797Z"
          fill="#345D9D"
        />
      </g>
      <defs>
        <clipPath id="clip0_849_384">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LtcIcon;
