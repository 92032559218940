import "./TWTLight.css";
function TWTLight() {
  return (
    <svg
      onClick={() => {
        window.open("https://twitter.com/Ravestag", "_blank");
      }}
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={"twt"}
        d="M33.9584 6.08179C32.7721 6.78593 30.392 7.74558 28.9928 8.02237C28.9518 8.03302 28.9183 8.0467 28.8788 8.05735C27.6423 6.83764 25.9481 6.08179 24.0729 6.08179C20.2937 6.08179 17.2292 9.14627 17.2292 12.9255C17.2292 13.1248 17.2125 13.4913 17.2292 13.686C12.1298 13.686 8.24867 11.0154 5.46554 7.60262C5.1629 8.36304 5.03058 9.5645 5.03058 10.693C5.03058 12.8236 6.69589 14.9163 9.28892 16.2136C8.81137 16.3368 8.28517 16.425 7.73767 16.425C6.85406 16.425 5.91875 16.1923 5.06252 15.4866C5.06252 15.5125 5.06252 15.5368 5.06252 15.5642C5.06252 18.542 8.22281 20.5692 11.0333 21.1335C10.463 21.4696 9.31325 21.503 8.75206 21.503C8.35665 21.503 6.95748 21.3221 6.58335 21.2521C7.36506 23.693 10.1847 25.0648 12.872 25.1135C10.7702 26.7621 9.31173 27.3735 5.00777 27.3735H3.54169C6.26094 29.1163 9.72387 30.4166 13.1944 30.4166C24.4942 30.4166 30.9167 21.8042 30.9167 13.686C30.9167 13.5552 30.9136 13.2814 30.9091 13.0061C30.9091 12.9788 30.9167 12.9529 30.9167 12.9255C30.9167 12.8845 30.9045 12.8449 30.9045 12.8039C30.9 12.597 30.8954 12.4039 30.8908 12.3035C32.0923 11.4366 33.1341 10.3553 33.9584 9.12345C32.8557 9.61316 31.6725 9.94166 30.43 10.0907C31.6984 9.33029 33.5006 7.51745 33.9584 6.08179Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}

export default TWTLight;
