const DogeIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_754_736)">
        <path
          d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
          fill="#C3A634"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.044 21.0019H25.2454V24.288H19.044V31.2139H22.9554C24.5036 31.2139 25.7701 31.0054 26.7562 30.5857C27.7423 30.1674 28.5157 29.5881 29.0778 28.8463C29.6526 28.075 30.044 27.1826 30.222 26.2373C30.4297 25.1708 30.5308 24.0864 30.5239 23C30.5308 21.9136 30.4297 20.8292 30.222 19.7628C30.0442 18.8173 29.6528 17.9249 29.0778 17.1537C28.5157 16.4119 27.7409 15.8326 26.7562 15.4143C25.7701 14.9946 24.5036 14.7861 22.9554 14.7861H19.044V21.0033V21.0019ZM15.0679 24.288H12.9375V21.0033H15.0679V11.5H24.4821C26.2214 11.5 27.7265 11.8019 28.9987 12.4013C30.2709 13.0036 31.3087 13.8244 32.1152 14.8666C32.9202 15.9074 33.5196 17.1264 33.9121 18.5222C34.3045 19.918 34.5 21.4116 34.5 23C34.5099 24.5126 34.3115 26.0193 33.9106 27.4778C33.5196 28.8722 32.9202 30.0926 32.1138 31.1334C31.3088 32.1756 30.2709 32.9964 28.9987 33.5987C27.7265 34.1996 26.22 34.5 24.4821 34.5H15.0679V24.288V24.288Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_754_736">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DogeIcon;
