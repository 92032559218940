import arrows from "../../images/reverse.svg";
import "./Calculator.css";
import { useEffect, useState } from "react";
import {
  calc,
  getCryptoCurrencies,
  getFiatCurrencies,
} from "../../api/calculator";
import SmallLoader from "../../ui/SmallLoader";
import Select from "../../ui/Select";

function Calculator({ className, refCode }) {
  const [price, setPrice] = useState();
  const [fiatCurrencies, setFiatCurrencies] = useState([]);
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
  const [exchangeType, setExchangeType] = useState("sell");
  const [loading, setLoading] = useState(false);
  const [fiatCurrency, setFiatCurrency] = useState();
  const [cryptoCurrency, setCryptoCurrency] = useState();
  const [cryptoValue, setCryptoValue] = useState(0);
  const [fiatValue, setFiatValue] = useState(0);
  const [network, setNetwork] = useState("trc20");
  const [error, setError] = useState("");
  const regex = new RegExp("^[0-9]*[.,]?[0-9]*$");
  const [reverse, setReverse] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [calculationType, setCalculationType] = useState("");
  const getFiat = () => {
    getFiatCurrencies().then((res) => {
      setFiatCurrencies(res);
    });
  };
  const getCrypto = () => {
    getCryptoCurrencies(exchangeType).then((res) => {
      setCryptoCurrencies(res);
    });
  };
  const calculate = (cv, fv, type, et, cc) => {
    setLoading(true);
    calc(
      et ? et : exchangeType,
      fiatCurrency,
      cc ? cc : cryptoCurrency,
      cv,
      fv,
      network,
      type
    )
      .then((res) => {
        /*  console.log(res);*/
        setPrice(res.rate);
        setCryptoValue(res.cryptoValue);
        setFiatValue(res.fiatValue);

        if (res.error) {
          setError(
            res.message.errorCode === "CS0010"
              ? "Minimum amount : " + res.message.minToExchange
              : res.message.errorCode === "CS0011"
              ? "Maximum amount : " + res.message.maxToExchange
              : res.message.errorCode === "CS0012"
              ? "Maximum amount : " + res.message.maxToExchange
              : res.message.errorCode === "CS0013"
              ? "Currency is not available"
              : "Something went wrong"
          );
        } else {
          setError("");
        }
      })
      .catch((e) => {
        setError("Something went wrong");
      });
    setLoading(false);
  };
  const handleFiatCurrencyChange = (e) => {
    setFiatCurrency(e.value);
  };
  const handleCryptoCurrencyChange = (e) => {
    setCryptoCurrency(e.value);

    setNetwork(cryptoCurrencies[e.value]?.networks[0]);
  };
  const handleNetworkChange = (e) => {
    setNetwork(e.target.value);
  };
  const handleCryptoInputChange = (e) => {
    setCalculationType("crypto");
    if (!regex.test(e.target.value)) {
      return;
    }
    setCryptoValue(e.target.value);
    setTimeout(() => {
      setLoading(true);
    }, 1200);
  };
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        calculate(cryptoValue, fiatValue, calculationType);
      }, 500);
    }
  }, [loading]);

  const handleFiatInputChange = (e) => {
    setCalculationType("fiat");
    if (!regex.test(e.target.value)) {
      return;
    }
    setFiatValue(e.target.value);
    setTimeout(() => {
      setLoading(true);
      //   calculate(null, e.target.value, calculationType);
    }, 1200);
  };
  useEffect(() => {
    getFiat();
    getCrypto();
  }, [exchangeType]);
  useEffect(() => {
    if (!fiatCurrency) {
      setFiatCurrency(Object.keys(fiatCurrencies)[0]);
    }
    if (!cryptoCurrency) {
      setCryptoCurrency(Object.keys(cryptoCurrencies)[0]);
    }
  }, [fiatCurrencies, cryptoCurrencies]);

  useEffect(() => {
    if (cryptoValue !== 0 || fiatValue !== 0) {
      setLoading(true);
      calculate(cryptoValue, fiatValue, "crypto");
    }
  }, [fiatCurrency, cryptoCurrency]);

  useEffect(() => {
    document.getElementById("input1").focus();
  }, []);

  return (
    <div className={"calculator " + className}>
      <div className="calculator-header">
        <h2>Try it out</h2>
      </div>
      <div className="calculator-input-group">
        <label>You give</label>
        <div className={"input-wrapper"}>
          <input
            style={{
              color: loading ? "rgba(59,59,62,0.4)" : "#3b3b3e",
            }}
            id={"input1"}
            disabled={loading}
            type={"text"}
            /* pattern="\d*"*/
            pattern="^[0-9]*[.,]?[0-9]*$"
            placeholder="0.0"
            onChange={
              exchangeType === "buy"
                ? handleFiatInputChange
                : handleCryptoInputChange
            }
            value={
              cryptoValue === 0 && fiatValue === 0
                ? ""
                : exchangeType === "sell"
                ? cryptoValue
                : fiatValue
            }
          />

          <Select
            defaultValue={
              exchangeType === "sell"
                ? {
                    label: Object.keys(cryptoCurrencies)[0],
                    value: Object.keys(cryptoCurrencies)[0],
                  }
                : {
                    label: Object.keys(fiatCurrencies)[0],
                    value: Object.keys(fiatCurrencies)[0],
                  }
            }
            options={
              exchangeType === "sell"
                ? Object.keys(cryptoCurrencies).map((key) => ({
                    label: cryptoCurrencies[key].code,
                    value: cryptoCurrencies[key].code,
                  }))
                : Object.keys(fiatCurrencies).map((key) => ({
                    label: fiatCurrencies[key].name,
                    value: fiatCurrencies[key].name,
                  }))
            }
            onSelect={
              exchangeType === "buy"
                ? handleFiatCurrencyChange
                : handleCryptoCurrencyChange
            }
            reset={reverse}
            setReset={setReverse}
          />
        </div>
      </div>
      <div className="calculator-delimiter">
        <img
          className={"reverse-button"}
          src={arrows}
          alt={"reverse"}
          onClick={() => {
            setExchangeType(exchangeType === "sell" ? "buy" : "sell");
            setCryptoCurrency("usdt");
            setLoading(true);
            setReverse(true);
            calculate(
              cryptoValue,
              fiatValue,
              exchangeType === "sell" ? "fiat" : "crypto",
              exchangeType === "sell" ? "buy" : "sell",
              "usdt"
            );
          }}
        />{" "}
        {loading ? <SmallLoader /> : null}
        {price && !loading ? (
          <div className={"coin-price"}>
            <div>1</div>
            <div>{exchangeType === "buy" ? fiatCurrency : cryptoCurrency}</div>
            <div>=</div>
            <div>{price}</div>
            <div>{exchangeType === "sell" ? fiatCurrency : cryptoCurrency}</div>
          </div>
        ) : null}
        {error ? (
          <div
            className={"calc-error"}
            onClick={() => {
              if (error.startsWith("Minimum amount : ")) {
                setCryptoValue(error.split(" : ")[1]);
                calculate(
                  exchangeType === "sell" ? error.split(" : ")[1] : null,
                  exchangeType === "sell" ? null : error.split(" : ")[1],
                  exchangeType === "sell" ? "crypto" : "fiat"
                );
              } else if (error.startsWith("Maximum amount : ")) {
                setCryptoValue(error.split(" : ")[1]);
                calculate(
                  exchangeType === "sell" ? error.split(" : ")[1] : null,
                  exchangeType === "sell" ? null : error.split(" : ")[1],
                  exchangeType === "sell" ? "crypto" : "fiat"
                );
              }
            }}
          >
            {error}
          </div>
        ) : null}
        <div className="calculator-delimiter-line"></div>
      </div>
      <div className="calculator-input-group">
        <label>You receive</label>
        <div className={"input-wrapper"}>
          <input
            disabled={disabled}
            style={{
              color: loading ? "rgba(59,59,62,0.4)" : "#3b3b3e",
            }}
            type={"text"}
            pattern="^[0-9]*[.,]?[0-9]*$"
            placeholder="0.0"
            onChange={
              exchangeType === "buy"
                ? handleCryptoInputChange
                : handleFiatInputChange
            }
            value={
              cryptoValue === 0 && fiatValue === 0
                ? ""
                : exchangeType === "sell"
                ? fiatValue
                : cryptoValue
            }
          />

          <Select
            defaultValue={
              exchangeType === "buy"
                ? {
                    label: Object.keys(cryptoCurrencies)[0],
                    value: Object.keys(cryptoCurrencies)[0],
                  }
                : {
                    label: Object.keys(fiatCurrencies)[0],
                    value: Object.keys(fiatCurrencies)[0],
                  }
            }
            options={
              exchangeType === "buy"
                ? Object.keys(cryptoCurrencies).map((key) => ({
                    label: cryptoCurrencies[key].code,
                    value: cryptoCurrencies[key].code,
                  }))
                : Object.keys(fiatCurrencies).map((key) => ({
                    label: fiatCurrencies[key].name,
                    value: fiatCurrencies[key].name,
                  }))
            }
            onSelect={
              exchangeType === "buy"
                ? handleCryptoCurrencyChange
                : handleFiatCurrencyChange
            }
            reset={reverse}
            setReset={setReverse}
          />
        </div>
      </div>
      <div className="calculator-networks">
        <label style={{ display: "flex" }}>
          Select
          <div style={{ margin: "0 5px", textTransform: "uppercase" }}>
            {cryptoCurrency}
          </div>
          network
        </label>
        <div className="calculator-networks-buttons">
          {cryptoCurrencies[cryptoCurrency]?.networks.map((n) => {
            return (
              <button
                key={n}
                className={
                  n === network || (exchangeType === "buy" && n === "trc20")
                    ? "calculator-networks-button active"
                    : "calculator-networks-button"
                }
                onClick={() => {
                  setNetwork(n);
                }}
              >
                {n}
              </button>
            );
          })}
        </div>
      </div>
      <div className="calculator-footer">
        <button
          className="calculator-footer-button"
          onClick={() => {
            if (cryptoValue === undefined || fiatValue === undefined) {
              window.open(
                `https://app.ravestag.com/?exchangeType=sell&cryptoValue=0&fiatValue=0&type=crypto&crypto=usdt&fiat=usd&paymentCode=defaultPayment&network=trc20${
                  refCode ? "&ref=" + refCode : ""
                }`,
                "_blank"
              );
            } else if (cryptoValue !== 0 && fiatValue !== 0) {
              window.open(
                `https://app.ravestag.com/?exchangeType=${exchangeType}&cryptoValue=${cryptoValue}&fiatValue=${fiatValue}&type=crypto&paymentCode=defaultPayment&crypto=${cryptoCurrency}&fiat=${fiatCurrency}&network=${network}${
                  refCode ? "&ref=" + refCode : ""
                }`,
                "_blank"
              );
            } else {
              window.open(
                `https://app.ravestag.com/?exchangeType=sell&cryptoValue=0&fiatValue=0&type=crypto&crypto=usdt&fiat=usd&paymentCode=defaultPayment&network=trc20${
                  refCode ? "&ref=" + refCode : ""
                }`,
                "_blank"
              );
            }
          }}
        >
          Exchange
        </button>
      </div>
    </div>
  );
}

export default Calculator;
