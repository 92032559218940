export async function getFiatCurrencies() {
  const response = await fetch(
    "https://core.ravestag.app/api/v1/demo/currencies/fiats"
  );
  const data = await response.json();
  return data;
}
export async function getCryptoCurrencies(exchangeType) {
  const response = await fetch(
    "https://core.ravestag.app/api/v1/demo/currencies/cryptos?exchangeType=" +
      exchangeType
  );
  const data = await response.json();
  return data;
}
export async function calc(
  exchangeType,
  fiatCurrency,
  cryptoCurrency,
  cryptoValue,
  fiatValue,
  network,
  type
) {
  const response = await fetch(
    `https://core.ravestag.app/api/v1/demo/calculator/exchange?exchangeType=${exchangeType}&cryptoValue=${
      cryptoValue ? cryptoValue : 0
    }&fiatValue=${
      fiatValue ? fiatValue : 0
    }&crypto=${cryptoCurrency}&fiat=${fiatCurrency}&network=${network}&paymentCode=defaultPayment&type=${type}`
  ).catch((e) => {
    return e;
  });
  return await response.json();
}
