const BtcIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1391_18689)">
        <path
          d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33Z"
          fill="#F7931A"
        />
        <path
          d="M23.9136 14.4581C24.2374 12.2966 22.5905 11.1344 20.3403 10.3589L21.0705 7.43016L19.2885 6.98672L18.5769 9.83812C18.1087 9.72056 17.6281 9.61125 17.1486 9.50194L17.8653 6.63197L16.0833 6.1875L15.3532 9.11522C14.9655 9.02653 14.5839 8.93991 14.2147 8.84709L14.2168 8.83781L11.7583 8.22422L11.2839 10.1279C11.2839 10.1279 12.607 10.4311 12.5791 10.4497C13.301 10.6301 13.431 11.1076 13.4093 11.4871L12.5781 14.8232C12.6276 14.8356 12.6916 14.8541 12.7637 14.882L12.575 14.8356L11.4097 19.5092C11.321 19.7278 11.0972 20.0568 10.5919 19.932C10.6105 19.9578 9.29668 19.6092 9.29668 19.6092L8.41187 21.649L10.7322 22.2276C11.1632 22.3358 11.5861 22.4493 12.0016 22.5555L11.2643 25.5172L13.0453 25.9607L13.7754 23.0319C14.2621 23.1629 14.7345 23.2846 15.1965 23.4001L14.4684 26.3165L16.2504 26.7599L16.9877 23.8043C20.0279 24.3798 22.3131 24.1477 23.2753 21.3984C24.0508 19.1854 23.2371 17.9077 21.6376 17.0754C22.803 16.8073 23.6795 16.0411 23.9136 14.4581ZM19.8402 20.1692C19.2905 22.3833 15.5626 21.186 14.3539 20.8859L15.3336 16.962C16.5422 17.2642 20.4166 17.8613 19.8402 20.1692ZM20.3919 14.4262C19.8897 16.4402 16.7877 15.4162 15.7822 15.1656L16.6691 11.6077C17.6746 11.8583 20.9158 12.3255 20.3919 14.4262Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1391_18689">
          <rect width="33" height="33" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BtcIcon;
