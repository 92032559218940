const BchIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="41"
      viewBox="0 0 46 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 40.9704C35.7025 40.9704 46 31.7989 46 20.4852C46 9.17153 35.7025 0 23 0C10.2975 0 0 9.17153 0 20.4852C0 31.7989 10.2975 40.9704 23 40.9704Z"
        fill="#8DC351"
      />
      <path
        d="M30.4849 13.4862C29.3694 10.9614 26.572 10.7335 23.3147 11.2969L22.1546 7.69531L19.6936 8.32395L20.8235 11.832C20.1766 11.9959 19.5182 12.1777 18.8642 12.357L17.7285 8.82584L15.269 9.4532L16.4262 13.0548C15.8972 13.2007 15.3768 13.3441 14.8665 13.4747L14.8622 13.4619L11.4668 14.3287L12.2215 16.6717C12.2215 16.6717 14.0299 16.1749 14.0083 16.2133C15.0059 15.9585 15.4961 16.3913 15.7333 16.8125L17.0558 20.9147C17.1234 20.898 17.2139 20.8775 17.3203 20.8635L17.0601 20.93L18.9102 26.6787C18.9562 26.9693 18.9159 27.4622 18.2202 27.6415C18.259 27.6581 16.429 28.0973 16.429 28.0973L16.7841 30.841L19.9869 30.0216C20.5834 29.8718 21.1728 29.731 21.7492 29.5863L22.9237 33.2288L25.3818 32.6015L24.2217 28.9974C24.8811 28.8415 25.5386 28.6793 26.194 28.5108L27.3469 32.0996L29.8093 31.4709L28.6392 27.8348C32.7087 26.566 35.3063 24.8978 34.5516 21.3436C33.945 18.4833 32.0734 17.6153 29.562 17.7126C30.781 16.7011 31.3057 15.3337 30.4849 13.4875V13.4862ZM29.5505 22.154C30.4274 24.8773 25.0943 25.9041 23.4268 26.3317L21.8729 21.5049C23.5404 21.0785 28.6349 19.3155 29.5491 22.1553L29.5505 22.154ZM26.2127 15.6372C27.009 18.1146 22.5514 18.9404 21.1613 19.2951L19.7525 14.9176C21.1412 14.563 25.3804 13.0548 26.2127 15.6384V15.6372Z"
        fill="white"
      />
    </svg>
  );
};

export default BchIcon;
