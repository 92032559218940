import "./SmallLoader.css";
function SmallLoader() {
  return (
    <div className="small-loader">
      <div className="small-loader__spinner">
        <div className="small-loader__bounce1"></div>
        <div className="small-loader__bounce2"></div>
        <div className="small-loader__bounce3"></div>
      </div>
    </div>
  );
}
export default SmallLoader;
