const DashIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="38"
      viewBox="0 0 46 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_849_379)">
        <path
          d="M30.1276 0H13.3816L11.9929 7.75925L27.1082 7.77717C34.5538 7.77717 36.749 10.4831 36.6863 14.963C36.6504 17.2567 35.6559 21.1453 35.2258 22.4086C34.0789 25.7686 31.7225 29.5945 22.8881 29.5855L8.19387 29.5765L6.80957 37.3448H23.5197C29.4153 37.3448 31.9151 36.6548 34.5762 35.4363C40.4629 32.7035 43.9662 26.8975 45.3729 19.3175C47.456 8.02805 44.8577 0 30.1276 0Z"
          fill="#008DE4"
        />
        <path
          d="M6.15543 14.7754C1.7651 14.7754 1.1379 17.6336 0.72575 19.3628C0.179198 21.6207 0 22.5436 0 22.5436H17.1492C21.5395 22.5436 22.1667 19.6854 22.5789 17.9561C23.1254 15.6983 23.3046 14.7754 23.3046 14.7754H6.15543Z"
          fill="#008DE4"
        />
      </g>
      <defs>
        <clipPath id="clip0_849_379">
          <rect width="46" height="37.3448" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DashIcon;
