import close from "../images/close.svg";
import { useEffect, useState } from "react";

function Promo() {
  const [show, setShow] = useState(sessionStorage.getItem("promo") !== "false");
  useEffect(() => {
    //sessionStorage.getItem("promo") === "false" && setShow(false);
  }, []);
  // sessionStorage.setItem("promo", "true");
  return (
    <div className={"promo"} style={{ display: `${show ? "flex" : "none"}` }}>
      <div className={"promo-content"}>
        <div style={{ gridColumn: "2" }} className={"promo-text"}>
          First deal with 50% discount for new users to get cash ❤
        </div>
        <img
          src={close}
          alt={"close"}
          style={{
            width: "16px",
            height: "16px",
            gridColumn: "3",
            cursor: "pointer",
          }}
          onClick={() => {
            setShow(false);
            sessionStorage.setItem("promo", "false");
          }}
        />
      </div>
    </div>
  );
}

export default Promo;
