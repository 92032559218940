const UsdIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
        fill="#6CDE07"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3438 27.5339C32.3438 31.2282 29.3451 33.6806 24.9176 34.0501V37.375H21.8845V34.017C18.8897 33.6793 16.0443 32.529 13.6562 30.6906L15.8873 27.6345C17.8753 29.1798 19.757 30.1875 22.0239 30.5239V23.9401C16.9338 22.6636 14.5633 20.8164 14.5633 17.0214C14.5633 13.3946 17.526 10.9092 21.8845 10.5728V8.625H24.9176V10.6404C27.2882 10.9037 29.5484 11.784 31.4726 13.1934L29.5191 16.3501C27.9852 15.2418 26.4169 14.536 24.7782 14.2011V20.5821C30.0782 21.8586 32.3438 23.9071 32.3438 27.5339V27.5339ZM22.0225 19.8763V13.9653C19.8274 14.1335 18.6774 15.3094 18.6774 16.7871C18.6774 18.1959 19.3387 19.1044 22.0239 19.8778L22.0225 19.8763ZM28.2296 27.7682C28.2296 26.2588 27.4965 25.3517 24.7782 24.5784V30.659C26.9747 30.4894 28.2296 29.3811 28.2296 27.7696V27.7682Z"
        fill="white"
      />
    </svg>
  );
};

export default UsdIcon;
