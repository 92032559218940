import React from "react";

function Menu() {
  const scrollToAnchor = (anchorName) => {
    const anchor = document.querySelector(anchorName);
    anchor.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={"menu"}>
      <div className={"desktop-menu-item active"}>
        <a onClick={() => scrollToAnchor("#services")}>Services</a>
      </div>
      <div className={"desktop-menu-item active"}>
        <a onClick={() => scrollToAnchor("#how_to")}>How to start?</a>
      </div>
      <div className={"desktop-menu-item active"}>
        <a onClick={() => scrollToAnchor("#offices")}>Offices</a>
      </div>
      <div className={"desktop-menu-item active"}>
        <a onClick={() => scrollToAnchor("#referrals")}>Referral program</a>
      </div>{" "}
      <div className={"desktop-menu-item active"}>
        <a onClick={() => scrollToAnchor("#faq")}>FAQ</a>{" "}
      </div>{" "}
      <div className={"desktop-menu-item active"}>
        <a onClick={() => scrollToAnchor("#partners")}>Our partners</a>
      </div>
    </div>
  );
}

export default Menu;
