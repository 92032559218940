import "./TGLight.css";
function TGLight() {
  return (
    <svg
      onClick={() => {
        window.open("https://t.me/ravestagnews", "_blank");
      }}
      className={"tg"}
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill=""
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.8771 4.53863C30.4376 4.55774 30.0343 4.68414 29.68 4.82378C29.3527 4.95269 28.1773 5.44671 26.3057 6.23174C24.434 7.01677 21.954 8.05762 19.3104 9.16944C14.0234 11.3931 8.08207 13.8944 5.03776 15.1755C4.93322 15.2191 4.50864 15.3491 4.03971 15.7072C3.5697 16.0662 3.04761 16.8453 3.04761 17.6974C3.04761 18.3847 3.39073 19.0848 3.80505 19.4856C4.21938 19.8864 4.63929 20.0737 4.99023 20.2133V20.2103C6.17653 20.683 9.71918 22.1005 10.5627 22.4381C10.8619 23.3347 12.1463 27.173 12.4518 28.1383H12.4489C12.6648 28.8214 12.8753 29.2667 13.1677 29.6383C13.3139 29.8241 13.4883 29.9925 13.7024 30.1255C13.7845 30.1765 13.8754 30.216 13.9667 30.2532C13.9794 30.2586 13.9926 30.2599 14.0053 30.2651L13.9697 30.2562C13.9962 30.2668 14.0199 30.2825 14.0469 30.2918C14.0909 30.3069 14.1214 30.3064 14.1806 30.3185C14.3889 30.3826 14.5969 30.4255 14.7895 30.4255C15.6163 30.4255 16.1232 29.9769 16.1232 29.9769L16.1559 29.9532L19.7263 26.9323L24.0987 30.978C24.1785 31.0907 24.8042 31.9374 26.2522 31.9374C27.1162 31.9374 27.7993 31.5097 28.2364 31.0611C28.6735 30.6125 28.9457 30.1535 29.0681 29.5314L29.0711 29.5284C29.1687 29.0263 33.3544 8.00803 33.3544 8.00803L33.3455 8.04367C33.4757 7.46219 33.5142 6.89985 33.3603 6.32679C33.2064 5.75373 32.7911 5.19449 32.291 4.90101C31.7908 4.60754 31.3165 4.51951 30.8771 4.53863ZM30.2771 7.86842C30.1117 8.69884 26.3621 27.5359 26.1304 28.7175L19.8154 22.8748L15.547 26.4838L16.7292 21.8619C16.7292 21.8619 24.8847 13.6066 25.3759 13.126C25.7714 12.7412 25.8542 12.6063 25.8542 12.4725C25.8542 12.2946 25.7626 12.1666 25.5512 12.1666C25.3611 12.1666 25.1029 12.3487 24.966 12.4339C23.2267 13.5182 15.8186 17.7399 12.1756 19.8123C11.9563 19.7244 8.66479 18.4027 6.89128 17.6944C10.0459 16.3667 15.4534 14.0916 20.4897 11.9735C23.1327 10.8618 25.6121 9.82006 27.4819 9.03578C29.0603 8.37375 29.8837 8.03096 30.2771 7.86842ZM26.0859 28.9343H26.0888L26.0859 28.9432C26.0878 28.9334 26.0844 28.942 26.0859 28.9343Z"
        fill=""
      />
    </svg>
  );
}

export default TGLight;
