const UsdcIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_754_740)">
        <path
          d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
          fill="#3E73C4"
        />
        <path
          d="M28.7817 26.0536C28.7817 23.0004 26.9417 21.9539 23.2617 21.5169C20.634 21.1675 20.1093 20.4704 20.1093 19.2485C20.1093 18.0266 20.9862 17.2417 22.7371 17.2417C24.314 17.2417 25.1909 17.765 25.6279 19.0745C25.6734 19.2012 25.7566 19.3108 25.8664 19.3887C25.9761 19.4666 26.1071 19.5089 26.2417 19.5101H27.6432C27.7242 19.5123 27.8047 19.498 27.88 19.4681C27.9553 19.4382 28.0237 19.3933 28.081 19.3362C28.1384 19.279 28.1836 19.2108 28.2139 19.1357C28.2442 19.0606 28.2588 18.9801 28.257 18.8992V18.8129C28.0858 17.8655 27.6064 17.0011 26.8933 16.3541C26.1803 15.7071 25.2736 15.3137 24.314 15.235V13.142C24.314 12.7927 24.0509 12.531 23.6139 12.4434H22.2986C21.9493 12.4434 21.6862 12.705 21.5985 13.142V15.1487C18.9694 15.4966 17.3062 17.2417 17.3062 19.4239C17.3062 22.3017 19.0571 23.4359 22.7371 23.8729C25.1909 24.3085 25.9786 24.8332 25.9786 26.229C25.9786 27.6234 24.7524 28.5836 23.0878 28.5836C20.8094 28.5836 20.0216 27.6248 19.7585 26.3152C19.6723 25.9674 19.4078 25.792 19.1447 25.792H17.6555C17.5747 25.79 17.4943 25.8045 17.4192 25.8345C17.3441 25.8644 17.2759 25.9094 17.2186 25.9665C17.1614 26.0236 17.1163 26.0917 17.0861 26.1667C17.056 26.2417 17.0413 26.3221 17.0431 26.4029V26.4892C17.3924 28.6713 18.7954 30.241 21.6862 30.678V32.7725C21.6862 33.1204 21.9493 33.3834 22.3863 33.4697H23.7016C24.0509 33.4697 24.314 33.208 24.4017 32.7725V30.6766C27.0309 30.241 28.7817 28.4082 28.7817 26.0522V26.0536Z"
          fill="white"
        />
        <path
          d="M18.5322 35.2144C11.6984 32.7707 8.19373 25.1807 10.7352 18.4633C12.0491 14.7976 14.9399 12.0074 18.5322 10.6979C18.883 10.5239 19.0569 10.2623 19.0569 9.82531V8.60344C19.0569 8.25556 18.883 7.99394 18.5322 7.90625C18.4445 7.90625 18.2692 7.90625 18.1815 7.9925C16.2099 8.60822 14.3795 9.60786 12.7958 10.9339C11.2121 12.2599 9.9063 13.8861 8.95366 15.7188C8.00102 17.5515 7.42032 19.5546 7.245 21.6127C7.06967 23.6708 7.30319 25.7432 7.93211 27.7107C9.50761 32.5982 13.2753 36.3501 18.1815 37.9198C18.5322 38.0938 18.883 37.9198 18.9692 37.5705C19.0569 37.4842 19.0569 37.3951 19.0569 37.2212V35.9993C19.0569 35.7377 18.7953 35.3898 18.5322 35.2144ZM27.8185 7.99394C27.4677 7.81856 27.117 7.99394 27.0307 8.34181C26.943 8.4295 26.943 8.51719 26.943 8.69113V9.913C26.943 10.2623 27.2047 10.6102 27.4677 10.7856C34.3016 13.2293 37.8062 20.8193 35.2647 27.5368C33.9509 31.2024 31.06 33.9926 27.4677 35.3021C27.117 35.4761 26.943 35.7377 26.943 36.1747V37.3966C26.943 37.7444 27.117 38.0061 27.4677 38.0938C27.5554 38.0938 27.7308 38.0938 27.8185 38.0075C29.7901 37.3918 31.6205 36.3921 33.2042 35.0661C34.7879 33.7401 36.0937 32.1139 37.0463 30.2812C37.9989 28.4485 38.5796 26.4454 38.755 24.3873C38.9303 22.3292 38.6968 20.2568 38.0679 18.2893C36.4924 13.3156 32.637 9.56369 27.8185 7.99394Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_754_740">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UsdcIcon;
