const UsdtIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_754_619)">
        <path
          d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
          fill="#26A17B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.7633 24.9885V24.9856C25.6051 24.9971 24.7901 25.046 22.9716 25.046C21.5198 25.046 20.4977 25.0029 20.1383 24.9856V24.9899C14.5493 24.7441 10.3777 23.7709 10.3777 22.6065C10.3777 21.4436 14.5493 20.4704 20.1383 20.2203V24.021C20.5035 24.0469 21.55 24.1087 22.9961 24.1087C24.7311 24.1087 25.6008 24.0369 25.7633 24.0225V20.2232C31.3408 20.4719 35.5023 21.445 35.5023 22.6065C35.5023 23.7709 31.3408 24.7412 25.7633 24.9885ZM25.7633 19.8279V16.4267H33.5459V11.2402H12.3557V16.4267H20.1383V19.8264C13.8133 20.1168 9.05664 21.3703 9.05664 22.871C9.05664 24.3718 13.8133 25.6239 20.1383 25.9157V36.8148H25.7633V25.9128C32.0782 25.6224 36.8234 24.3704 36.8234 22.871C36.8234 21.3717 32.0782 20.1197 25.7633 19.8279Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_754_619">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UsdtIcon;
