import "./IGLight.css";

function IGLight() {
  return (
    <svg
      onClick={() => {
        window.open("https://www.instagram.com/ravestagcrypto", "_blank");
      }}
      className={"ig"}
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill=""
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 4.5625C8.46765 4.5625 5.0625 7.96765 5.0625 12.1667V24.3333C5.0625 28.5324 8.46765 31.9375 12.6667 31.9375H24.8333C29.0324 31.9375 32.4375 28.5324 32.4375 24.3333V12.1667C32.4375 7.96765 29.0324 4.5625 24.8333 4.5625H12.6667ZM27.875 7.60417C28.7145 7.60417 29.3958 8.2855 29.3958 9.125C29.3958 9.9645 28.7145 10.6458 27.875 10.6458C27.0355 10.6458 26.3542 9.9645 26.3542 9.125C26.3542 8.2855 27.0355 7.60417 27.875 7.60417ZM18.75 10.6458C22.949 10.6458 26.3542 14.051 26.3542 18.25C26.3542 22.449 22.949 25.8542 18.75 25.8542C14.551 25.8542 11.1458 22.449 11.1458 18.25C11.1458 14.051 14.551 10.6458 18.75 10.6458ZM18.75 13.6875C17.54 13.6875 16.3795 14.1682 15.5238 15.0238C14.6682 15.8795 14.1875 17.04 14.1875 18.25C14.1875 19.4601 14.6682 20.6205 15.5238 21.4762C16.3795 22.3318 17.54 22.8125 18.75 22.8125C19.9601 22.8125 21.1205 22.3318 21.9762 21.4762C22.8318 20.6205 23.3125 19.4601 23.3125 18.25C23.3125 17.04 22.8318 15.8795 21.9762 15.0238C21.1205 14.1682 19.9601 13.6875 18.75 13.6875Z"
        fill=""
      />
    </svg>
  );
}

export default IGLight;
