import React, { useEffect, useRef, useState } from "react";
import "./Select.css";
import UsdIcon from "../images/exchange/UsdIcon.tsx";
import EurIcon from "../images/exchange/EurIcon.tsx";
import GelIcon from "../images/exchange/GelIcon.tsx";
import BchIcon from "../images/exchange/BchIcon.tsx";
import BtcIcon from "../images/exchange/BtcIcon.tsx";
import DogeIcon from "../images/exchange/DogeIcon.tsx";
import UsdtIcon from "../images/exchange/UsdtIcon.tsx";
import DaiIcon from "../images/exchange/DaiIcon.tsx";
import DashIcon from "../images/exchange/DashIcon.tsx";
import EthIcon from "../images/exchange/EthIcon.tsx";
import LtcIcon from "../images/exchange/LtcIcon.tsx";
import SolIcon from "../images/exchange/SolIcon.tsx";
import TrxIcon from "../images/exchange/TrxIcon.tsx";
import UsdcIcon from "../images/exchange/UsdcIcon.tsx";
import XmrIcon from "../images/exchange/XmrIcon.tsx";
import XrpIcon from "../images/exchange/XrpIcon.tsx";

function CustomDropdown({ options, onSelect, defaultValue, reset, setReset }) {
  const ExchangeIcons = {
    // fiat
    usd: <UsdIcon />,
    eur: <EurIcon />,
    gel: <GelIcon />,
    // crypto
    bch: <BchIcon />,
    btc: <BtcIcon />,
    doge: <DogeIcon />,
    usdt: <UsdtIcon />,
    dai: <DaiIcon />,
    dash: <DashIcon />,
    eth: <EthIcon />,
    ltc: <LtcIcon />,
    sol: <SolIcon />,
    trx: <TrxIcon />,
    usdc: <UsdcIcon />,
    xmr: <XmrIcon />,
    xrp: <XrpIcon />,
  };
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (reset) {
      setSelectedOption(defaultValue);
      setReset(false);
    }
    /* console.log(defaultValue);*/
  }, [reset]);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      style={
        isOpen && options.length > 1
          ? { borderRadius: "12px 12px 0 0 " }
          : { borderRadius: "12px" }
      }
      className="custom-dropdown"
      onClick={toggleOpen}
      ref={dropdownRef}
    >
      <div className="selected-option" onClick={toggleOpen}>
        {selectedOption
          ? ExchangeIcons[selectedOption?.value]
          : ExchangeIcons[defaultValue?.value]}
        <span>
          {selectedOption ? selectedOption?.label : defaultValue?.value}
        </span>
      </div>
      {isOpen && (
        <ul className="options-list">
          {options.map((option, index) =>
            selectedOption?.value !== option?.value &&
            (selectedOption || option?.value !== defaultValue?.value) ? (
              <li
                key={option?.value}
                className="option"
                style={
                  options.length - 1 === index
                    ? { borderRadius: "0 0 12px 12px" }
                    : { borderRadius: "0" }
                }
                onClick={() => handleOptionClick(option)}
              >
                {ExchangeIcons[option?.value]}
                <span>{option?.label}</span>
              </li>
            ) : null
          )}
        </ul>
      )}
    </div>
  );
}

export default CustomDropdown;
