const XrpIcon = ({ width = 24, height = 24, color = "#212121" }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
        fill="#23292F"
      />
      <path
        d="M33.1631 11.5H37.3175L28.6709 20.0632C27.1592 21.5526 25.1222 22.3875 23 22.3875C20.8778 22.3875 18.8408 21.5526 17.3291 20.0632L8.67531 11.5H12.8369L19.4062 18.0018C20.3629 18.946 21.653 19.4755 22.9971 19.4755C24.3413 19.4755 25.6314 18.946 26.588 18.0018L33.1631 11.5ZM12.7866 35.3093H8.625L17.3291 26.6944C18.8408 25.2049 20.8778 24.37 23 24.37C25.1222 24.37 27.1592 25.2049 28.6709 26.6944L37.375 35.3079H33.2134L26.5938 28.75C25.6371 27.8058 24.347 27.2763 23.0029 27.2763C21.6587 27.2763 20.3686 27.8058 19.412 28.75L12.7851 35.3093H12.7866Z"
        fill="white"
      />
    </svg>
  );
};

export default XrpIcon;
